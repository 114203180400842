import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:id',
    name: 'layout',
    component: () =>
      import('@/views/layout/layout.vue'),
    meta: {
      keepAlive: true, // 此组件需要被缓存
      // deepth: 1,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
